/*
	Event handlers for jquery-ujs and some helper functions

	Jquery UJS events are documented here:
	https://github.com/rails/jquery-ujs/wiki/ajax

	HTML attributes are documented here:
	https://github.com/rails/jquery-ujs/wiki/Unobtrusive-scripting-support-for-jQuery-%28list-of-data-attributes%29#data-disable-with
*/
//Before sending an ajax we remove any errors we may have shown if it's a form
$(document).on('ajax:beforeSend',function(event,xhr,settings){
	//If this ajax is loading a modal
	if(event.target.tagName=='A' || event.target.tagName=='BUTTON'){
		//get the modal size
		let modalSize=event.target.getAttribute('data-modal-size');
		modalSize = (modalSize === undefined || modalSize == null || modalSize == '') ? 'modal-xl' : modalSize;
		//Create a loading modal with ability to abort ajax request when cancel is clicked
		let modal=window.ajaxModalLoadingCreate(xhr,event.target.getAttribute('href'),modalSize);
	}
	//If this ajax is submitting the form
	if(event.target.tagName=='FORM'){
		//Get modal
		let modal=$(event.target).closest(".modal");
		//Remove any errors
		modal.find(".is-invalid").removeClass('is-invalid');
		modal.find(".form-error").remove();
	}
});

//When request was sent
$(document).on('ajax:send',function(event,xhr){
	if(event.target.tagName=='FORM'){
		//Get modal
		let modal=$(event.target).closest(".modal");
		//Disable all enabled elements, add special parameter to enable them back
		let elementsToDisable=modal.find(":input,.btn").not("[type='hidden']").not("[disabled]");
		elementsToDisable.prop('disabled',true).attr('data-temp-disabled','true');
	}
});

//When validator returns error (btw it should return status code 422)
$(document).on('ajax:error',function(event,xhr,status,error){
	//If error happened while loading a modal
	if(event.target.tagName=='A' || event.target.tagName=='BUTTON'){
		let modal=$(".modal:visible");
		modal.find(".modal-body").html("<div class='alert bg-alert text-white'>Error loading data.</div>");
	}
	//If error happened when submitting the form
	if(event.target.tagName=='FORM'){

		//Remove existing errors
		$(event.target).find(".is-invalid").removeClass('is-invalid');
		$(event.target).find(".form-error").remove();
		//Add errors we received from backend
		$.each(xhr.responseJSON,function(i,e){
			let el=$(event.target).find('[name="'+i+'"]');
			if(el != undefined) {
				el.addClass('is-invalid');
				el.after($('<span class="form-error text-danger">'+e[0]+'</span>'));
			}
		});
		//On single error message (which actually shouldn't happen)
		if(xhr.responseJSON && xhr.responseJSON.error!=undefined){
			alert(xhr.responseJSON.error);
		}
		//Get modal
		let modal=$(event.target).closest(".modal");
		let elementsToEnable=modal.find("[data-temp-disabled]");
		elementsToEnable.removeAttr('data-temp-disabled').prop('disabled',false);
	}
});


//Successfull ajax
$(document).on('ajax:success',function(event,data,status,xhr){
	//If this ajax is result of clicking on a button to load a modal
	if(event.target.tagName=='A' || event.target.tagName=='BUTTON'){
		//get the modal size
		let modalSize=event.target.getAttribute('data-modal-size');
		modalSize = (modalSize === undefined || modalSize == null || modalSize == '') ? 'modal-xl' : modalSize;
		let modal=ajaxModalLoadingReplace(data,modalSize);
	}
	//If this ajax is a result of submitting a form
	if(event.target.tagName=='FORM'){
		//Get modal
		let modal=$(event.target).closest(".modal");
		//If this modal have set an OnSuccess function, we run and delete it
		if(modal[0].hasAttribute('data-on-success-function')){
			let uniqueName=modal.attr('data-on-success-function');
			let sResult=window[uniqueName](modal,event,data,status,xhr);
			delete window[uniqueName];
			if(sResult===false) return;
		//If form has data-refresh='true' we refresh the page
		}else if(event.target.hasAttribute("data-refresh") && event.target.getAttribute("data-refresh")=='true'){
			window.location.reload();
			return; //Do not hide the modal
		}
		//If this form was inside of a modal, hide the modal
		if(modal.length>0) modal.hide().remove();
	}
});

//Creates a 'loading' modal with ability to cancel 
//current ajax request that loads content for this modal
window.ajaxModalLoadingCreate=function(modalxhr,url,modalSize){

	//Remove any loading modals that could be left by other scripts
	$(".modal-loading").remove();
	$(".modal-backdrop").remove();

	//Clone invisible loading modal
	let newModalHTML=$("#modalLoading").clone().removeAttr('id').data('url',url).addClass("modal-loading");
	newModalHTML.find(".modal-dialog").addClass(modalSize);

	//remove the buttons
	newModalHTML.find(".modal-footer .btn-success").remove();

	$('body').append(newModalHTML);

	//If user closes a 'loading' modal, we cancel ajax
	window.addEventListener('hide.bs.modal',function(){
		if(typeof modalxhr==='object'){
			modalxhr.abort();
		}
	});

	//Remove HTML element of the modal after it's hidden
	window.addEventListener('hidden.bs.modal',function(){
		newModalHTML.remove();
	});

	//Ususally not needed but good to have
	return newModalHTML;
}

window.ajaxModalLoadingReplace=function(newModalHTML,modalSize){
	//Remove any loading modals that could be left by other scripts
	$(".modal-loading").remove();
	$(".modal-backdrop").remove();

	//Create a new modal from received HTML
	modal=$(newModalHTML);
	modal.find(".modal-dialog").addClass(modalSize);

	//append modal to body
	$('body').append(modal);
	modal.removeClass('fade');

	//Initiatlize and show new bootstrap modal
	var newModal = new bootstrap.Modal(
		modal, {keyboard: false, focus:true}
	);

	//After showing this modal add fade class so it will animate on close
	window.addEventListener('show.bs.modal',function(e){
		modal.addClass('fade');
	});

	//Remove HTML element of the modal after it's hidden
	window.addEventListener('hidden.bs.modal',function(e){
		modal.remove();
	});

	newModal.show();

	//If this modal has set an OnLoad function, we run and delete it
	if(typeof window.ajaxModalOnLoad=='function'){
		window.ajaxModalOnLoad(modal);
		delete window.ajaxModalOnLoad;
	}
	//If this modal sets an OnSuccess function, we assign it a unique name and store it in the modal param
	if(typeof window.ajaxModalOnSuccess=='function'){
		let uniqueName='_'+Math.random().toString(36).substr(2, 9);
		window[uniqueName]=window.ajaxModalOnSuccess;
		modal.attr('data-on-success-function',uniqueName);
		delete window.ajaxModalOnSuccess;
	}

	//Autofocus
	modal.find('input[autofocus]').focus();
	
	//SelectPicker
  $('.selectpicker').selectpicker();

  // ===== Popover ====
  //Enable popovers everywhere
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

	// ===== Tooltip ====
    //Enable tooltips everywhere
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    });
	//So we can modify
	return modal;
}

window.makeQueryString=function(obj,prefix){
	var str=[],p;
	for(p in obj){
		if(obj.hasOwnProperty(p)){
			var k=prefix?prefix+"["+p+"]":p,v=obj[p];
			str.push((v!==null && typeof v==="object")?window.makeQueryString(v,k):encodeURIComponent(k)+"="+encodeURIComponent(v));
		}
	}
	return str.join("&");
}

//A function to create a 'loading' modal and then replace it with an ajax-loaded modal
window.ajaxModal=function(route,params,queryObj,modalSize){
	modalSize = (modalSize === undefined || modalSize == null || modalSize == '') ? 'modal-xl' : modalSize;
	let queryStr=window.makeQueryString(queryObj);
	let url=getRoute(route,params)+(queryStr.length>0?"?"+queryStr:"");
	let modalxhr=$.ajax({
		url:url,
		success:function(response){
			//Replace loading modal with html we received
			let modal=window.ajaxModalLoadingReplace(response,modalSize);
		},
		error:function(xhr,status,error){
			modal.find(".modal-body").html("<div class='alert bg-alert text-white'>Error loading data.</div>");
		}
	});
	//Create a 'loading' modal with ability to cancel this ajax call if user clicks 'cancel'
	let modal=window.ajaxModalLoadingCreate(modalxhr,url,modalSize);
}