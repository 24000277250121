import './bootstrap';

//markdown previewer for FAQs
// import TextareaMarkdown from 'textarea-markdown';

//markdown editor for FAQs
import Editor from '@toast-ui/editor';

//import toastui css
// import '@toast-ui/editor/dist/toastui-editor-only.css';

//set a eventlistener to load markdown preview in ujs modal
window.addEventListener('shown.bs.modal', function () {

    //TextareaMarkdown
    // const editorModal = document.querySelector('#editorModal');
    // if(editorModal != null) new TextareaMarkdown(editorModal);

    //only using ToastUI for faq and changelog
    const faqForm = document.querySelector('#faqForm');
    const changelogForm = document.querySelector('#changelogForm');
    const noteForm = document.querySelector('#noteForm');

    //only display the editor for [Faq, Changelog, Note] models
    if (!faqForm && !changelogForm && !noteForm) {
        return
    }

    //Toast UI
    const editor = new Editor({
        el: document.querySelector('#editor'),
        height: 'auto',
        minHeight: '300px',
        placeholder: document.querySelector('#description').getAttribute('data-placeholder'),
        previewStyle: 'tab',
        hideModeSwitch: true,
        initialEditType: 'wysiwyg',
        initialValue: document.querySelector('#description').value,
        usageStatistics: false,
        autofocus: true,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          //['hr', 'quote'],
          ['ul', 'ol', 'indent', 'outdent'],
          ['link'], //'table', 'image', 
          ['code', 'codeblock'],
        ]
    });

    //Toast UIlistener: copy typed text into hidden description field to save it in DB
    document.addEventListener('mouseup', e => {
        e.preventDefault();
        console.log(editor.getMarkdown());
        document.querySelector('#description').value= editor.getMarkdown();
    });

    //Toast UI listener for validation: add border .danger if empty
    document.addEventListener('submit', e => {
        document.querySelector('.toastui-editor-main-container').removeClass('border border-danger');
        e.preventDefault();
        document.querySelector('.toastui-editor-main-container').addClass('.border border.danger');
        if(editor.getMarkdown().length !== 0) {
            document.querySelector('#description').value= editor.getMarkdown();
        } else {
            document.querySelector('.toastui-editor-main-container').addClass('border border-danger');
        }
    });

});

var observeDOM=(function(){
  var MutationObserver=window.MutationObserver || window.WebKitMutationObserver;
  return function( obj, callback ){
    if(!obj || !obj.nodeType===1) return;
    if(MutationObserver){
      var obs=new MutationObserver(function(mutations, observer){callback(mutations);});
      obs.observe(obj,{childList:true,subtree:true});
    }else if(window.addEventListener){
      obj.addEventListener('DOMNodeInserted', callback, false);
      obj.addEventListener('DOMNodeRemoved', callback, false);
    }
  }
})();

window.cookies=function(name,value){
    if(value!=undefined){ //Set cookie
        document.cookie=name+'='+escape(value)+';path=/';
    }else{ //Get cookie
        var i,x,y,ARRcookies=document.cookie.split(";");
        for(i=0;i<ARRcookies.length;i++){
            x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
            y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
            x=x.replace(/^[\s]+|[\s]+$/g,"");
            if(x==name) return unescape(y);
        }
        return false;
    }
}

$(document).ready(function(){

    // ===== Directory search - external only ==== 
    const search = document.getElementById('query');
    const divResults = document.getElementById('search-suggestions');
    const old_html = divResults;
    function getContent(){
        const url = directorySearch;
        const searchValue = search.value;
        const xhr = new XMLHttpRequest();
        xhr.open('GET',url+'?query=' + searchValue ,true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('X-CSRF-TOKEN', "{{ csrf_token() }}");
        xhr.onreadystatechange = function() {
            
            if(xhr.readyState == 4 && xhr.status == 200)
            {
                divResults.innerHTML = xhr.responseText;
            } 
        }
        xhr.send()
    }

    if(search) search.addEventListener('input',getContent);

    // Match height for all cards using jquery-match-height. BB 01/27/2018
    $('.match-height').matchHeight();
    
    // Initialize jQuery datepicker.
    $(".datepicker" ).datepicker({dateFormat:"yy-mm-dd"});

    // Initialize jQuery selectpicker.
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';
    $('.selectpicker').selectpicker();

    // Initialize editable.
    $('.editable').editable({
        highlight: '#FFFF80',
        emptytext: 'edit',
        emptyclass: 'text-muted fw-light',
        //source: [],
        params: function(params) {
            //originally params contain pk, name and value
            params.view = $(this).data('view');
            return params;
        },
        //block the rendering so we can customize it
        display: function(value, sourceData) {
            return false;   //disable this method
        },
        success: function(response, newValue) {
            //if a view is passed from the controller, use it
            if(response.view) {
                $(this).html(response.view);
            } else {
                $(this).html(response.newValue);
                return response;
            }
        },
        error: function(response, newValue) {
            if(response.status === 500) {
                return 'Service unavailable. Please try later.';
            } else {
                return response.responseText;
            }
        }
    });

    // ===== Tooltip ====
    //Enable tooltips everywhere
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    });

    // ===== Popover ====
    //Enable popovers everywhere
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
    })

    //reveal link on application view page
    $("a").click(function(event) {
        //alert(event.target.id);
        var id = event.target.dataset.id;
        $("span#"+id).toggleClass("blur");
    });
    
    // ===== Scroll to Top ==== 
    $(window).scroll(function() {
        if ($(this).scrollTop() >= 50) {        // If page is scrolled more than 50px
            $('#return-to-top').fadeIn(200);    // Fade in the arrow
        } else {
            $('#return-to-top').fadeOut(200);   // Else fade out the arrow
        }
    });
    $('#return-to-top').click(function() {      // When arrow is clicked
        $('body,html').animate({
            scrollTop : 0                       // Scroll to top of body
        }, 500);
    });
    
    // ===== Delay for application live search ==== 
    window.delay = function(callback, ms) {
        var timer = 0;
        return function() {
          var context = this, 
            args = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
              callback.apply(context, args);
          }, ms || 0);
      };
    }

    // ===== Organization Like button ====
    $('[data-organization-like]').off("click").click(function(e) {
        var self = $(this),
        oID = self.data('organization-like');
        self.prop('disabled', true);

        $.ajax({
            type:'GET',
            url : '/organization/'+oID+'/like',
            headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')},
            success:function(data){

                $("#count_"+oID).html(data.current_count);
                if ($("#text_"+oID, self).html() == "Like") {
                    $("#text_"+oID, self).html('Unlike');
                    $("#icon_"+oID, self).addClass('fa-check').removeClass('fa-thumbs-o-up');
                }
                else {
                    $("#text_"+oID, self).html('Like');
                    $("#icon_"+oID, self).addClass('fa-thumbs-o-up').removeClass('fa-check');
                }
            },
            error:function(xhr,textStatus,error){
                if(xhr.status==401){ //HTTP 401 Unauthorized
                    console.log('Redirecting to login...');
                    window.location.href="/login";
                }else{
                    alert(xhr.statusText);
                }
            },
            complete:function(){
                self.prop('disabled', false);
            }

        })
    });

    // ===== Organization Like button ====
    $('[data-user-follow]').off("click").click(function(e) {
        var self = $(this),
        uniqID = self.data('user-follow');
        self.prop('disabled', true);
        $.ajax({
            type:'GET',
            url : '/profile/'+uniqID+'/follow',
            headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')},
            success:function(data){
                $("#count_"+uniqID, self).html(data.current_count);
                var text = $("#text_"+uniqID, self).html();
                $("#text_"+uniqID, self).html(text == "Follow" ? "Un-follow" : 'Follow');

            },
            error:function(xhr,textStatus,error){
                if(xhr.status==401){ //HTTP 401 Unauthorized
                    console.log('Redirecting to login...');
                    window.location.href="/login";
                }else{
                    alert(xhr.statusText);
                }
            },
            complete:function(){
                self.prop('disabled', false);
            }

        })
    });

    // ===== Thread Like button ====
    $('[data-thread-like]').off("click").click(function(e) {
        var self = $(this),
        id = self.data('thread-like');
        url = self.data('thread-url');
        self.prop('disabled', true);

        $.ajax({
            type:'GET',
            url : url,
            headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')},
            success:function(data){
                $("#count_"+id, self).html(data.current_count);
                var text = $("#count_"+id, self).html();
                //$("#icon_"+id, self).html(text == "Follow" ? "Un-follow" : 'Follow');

            },
            error:function(xhr,textStatus,error){
                if(xhr.status==401){ //HTTP 401 Unauthorized
                    console.log('Redirecting to login...');
                    window.location.href="/login";
                }else{
                    alert(xhr.statusText);
                }
            },
            complete:function(){
                self.prop('disabled', false);
            }

        })
    });

    $('.addApp.addAppInit').removeClass('addAppInit disabled');
    observeDOM($('body')[0],function(){$('.addApp.addAppInit').removeClass('addAppInit disabled');});

    $(document).on('click','.addApp',function(e){
        e.preventDefault();
        addApp($(this));
    });

    $(document).on('click','.setStackPublic',function(e){
        var btn=$(this);
        $.ajax({
            url:btn.attr('route'),
            type:'POST',
            dataType:'json',
            data:{state:btn.data('state')},
            headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')},
            beforeSend:function(){
                btn.closest("div").find(">").addClass('disabled');
            },
            success:function(data,textStatus,xhr){
                if(data.success){
                    if(window.location.pathname.match(/^\/stack\//)){
                        window.location.reload(true);
                    }else{
                        btn.closest("div").find(">.dropdown-toggle").html(btn.html());
                    }
                }
            },
            error:function(xhr,textStatus,error){
                alert(xhr.statusText);
            },
            complete:function(){
                btn.closest("div").find(">").removeClass('disabled');
            }
        });
    });

    function addApp(btn,oID,stateID){
        event.preventDefault();

        var btn=$(btn);
        var service_key=$(btn).data('add_service_key');
        var url=$(btn).data('url');
        var triggerAddBtn = document.querySelector("[data-add_service_key="+service_key+"]");

        $.ajax({
            url:url,
            type:'POST',
            data:
            {
                oID:(oID!=undefined?oID:false),
                stateID:(stateID!=undefined?stateID:false)
            },
            headers:{
                'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')
            },
            datatype:'json',
            beforeSend:function(){

                console.log("Preparing data: "+oID+" "+stateID+" "+url);

                $('.addApp').each(function(){ //Allow only one popover
                    var tbtn=$(this);
                    if(!tbtn.hasClass('addAppLoading')) { 
                        tbtn.removeClass('disabled');
                    }
                });
                btn.addClass('addAppLoading disabled');
            },
            success:function(data,textStatus,xhr){
                console.log("Saving... please wait.");

                if(data.hasSCID){
                    btn.attr('title','Saved ('+data.hasSCID+')');
                }
                if(data.success){
                    btn.removeClass('addAppLoading disabled btn-primary').addClass('btn-success addAppSuccess');
                    btn.find('.fas').removeClass('fa-plus').addClass('fa-check');
                    setTimeout(function(){btn.removeClass('addAppSuccess');},1000);
                }else if(data.html){
                    // btn.removeClass('addAppLoading');
                    var content=$(data.html);
                    var popover = bootstrap.Popover.getOrCreateInstance(triggerAddBtn, {
                        trigger: 'focus',
                        container:'body',
                        placement:'bottom',
                        html:true,
                        content:content,
                        selector:'[data-add_service_key='+service_key+']'
                    });
                    popover.show();
                    console.log(service_key+" popover shown.");

                    triggerAddBtn.addEventListener('hidden.bs.popover',function(e){
                        popover.dispose();
                        btn.removeClass('disabled');
                        console.log(service_key+" popover disposed.");
                    });

                    triggerAddBtn.addEventListener('shown.bs.popover',function(e){
                        btn.addClass("disabled");
                        content.closest('.popover').find('.popover-title').remove();
                        content.closest('.popover').css('z-index','9999');
                        content.find('.popover-title').remove();
                        
                        content.find('.btn.select').click(function(){

                            var stateid=$(this).data('stateid');
                            var oid=$(this).data('oid');
                            var url=$(this).data('url');

                            btn.removeClass('disabled');
                            console.log("Sending data...");
                            addApp($(this),oid,stateid);
                        });
                        content.find('.btn.remove').click(function(){
                            btn.removeClass('disabled');
                            removeApp(popover,$(this));
                        });
                        content.find('.btn.cancel').click(function(){
                            btn.removeClass('disabled');
                            popover.dispose();
                            console.log(service_key+" popover disposed.");
                        });
                    });
                }
            },
            error:function(xhr,textStatus,error){
                if(xhr.status==401){ //HTTP 401 Unauthorized
                    console.log('Redirecting to login...');
                    window.location.href="/login";
                }else{
                    alert(xhr.statusText);
                }
            },
            complete:function(){
                btn.removeClass('addAppLoading disabled');
            }
        });
    }

   function removeApp(btn,btnRemove){
    var btn=$(btn);
        if(!confirm('Do you really want to remove this from your stack?')){
            btn.removeClass('addAppLoading disabled');
            return false;
        }
        $.ajax({
            url:btnRemove.data('url'),
            type:'POST',
            dataType:'json',
            data:{},
            headers:{'X-CSRF-TOKEN':$('meta[name="csrf-token"]').attr('content')},
            beforeSend:function(){
                btn.addClass('addAppLoading disabled');
            },
            success:function(data){
                if(data.success){
                    btn.removeClass('addAppLoading disabled btn-primary').addClass('btn-success addAppSuccess');
                    if(data.hasSCID){
                        btn.attr('title','Added to stack ('+data.hasSCID+')');
                    }else{
                        btn.find('.fas').removeClass('fa-check').addClass('fa-plus');
                    }
                    setTimeout(function(){btn.removeClass('addAppSuccess');},1000);
                }else{
                    btn.removeClass('addAppLoading disabled');
                }
            },
            error: function(response){ 
                alert(response.responseJSON.error);
            }
        });
    }
    
    /*
    metaAddApps=$("head meta[name=addApp]");
    if(metaAddApps.length){
        metaAddApps.each(function(){
            key=$(this).attr('content');
            btn=$("a.addApp[data-add_service_key='"+key+"']");
            btn.click();
        });
    }
    */
});
