//If server returns collaborators component
$(document).on('ajax:success',function(event,data,status,xhr){
	if(xhr.responseJSON!=undefined && xhr.responseJSON.collaboratorsComponent!=undefined){
		let html=xhr.responseJSON.collaboratorsComponent;
		let toReplace=$(".collaborators-component");
		if(toReplace.length>0){
			$(".collaborators-component").each(function(){
				let component=$(this);
				let replacement=$(html);
				component.find('input[type=checkbox]').each(function(){
					if(this.checked){
						replacement.find("input[type=checkbox][name=\""+this.name+"\"][value=\""+this.value+"\"]").prop("checked",true);
					}
				});
				component.replaceWith(replacement);
			});
		}else{
			window.location.reload();
		}
	}
});