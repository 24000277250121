// window._ = require('lodash');
try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('@popperjs/core');
    window.Bootstrap = window.bootstrap = require('bootstrap');
    window.matchHeight = require('jquery-match-height');
    window.List = require('list.js');
    window.svgline = require('./svgline.js');
    window.moment = require('moment');
    require( 'jszip' );
    require( 'pdfmake' );
    require( 'textarea-markdown' );
    window.dt = require( 'datatables.net-bs5' );
    require( 'datatables.net-buttons-bs5' );
    require( 'datatables.net-buttons/js/buttons.html5.js' );
    require( 'datatables.net-buttons/js/buttons.print.js' );
    require( 'datatables.net-colreorder-bs5' );
    require( 'datatables.net-fixedcolumns-bs5' );
    require( 'datatables.net-fixedheader-bs5' );
    require( 'datatables.net-searchpanes-bs5' );
    require('jquery-ujs');//Based on this article https://medium.com/@vannyvanngoggygogg/laravel-on-modals-2c41a98898e4
    require('./jquery/jquery-ujs-modals'); //Written some custom event handlers for jquery-ujs and for modals. Andrii Sudyn
    require('./jquery/jquery-ujs-collaborators'); //Custom functions to update collaborators component with ajax. Andrii Sudyn
    require('bootstrap-select');
    require('bootstrap-editable');
    require('textarea-helper');
    require('./zoom.js');

} catch (e) {}
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/sortable.js';
import 'daterangepicker';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
